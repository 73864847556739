import { Modal } from "antd";
import React from "react";
import { createPortal } from "react-dom";
import { Discuss } from "react-loader-spinner";
const LoadingOverlay = () => {
  return createPortal(
    <Modal
      className="container-loading-overlay"
      width={"100%"}
      open
      footer={false}
      closable={false}
      transitionName="none"
      maskTransitionName="none"
      styles={{
        content: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <Discuss
        height="80"
        width="80"
        ariaLabel="discuss-loading"
        wrapperStyle={{}}
        wrapperClass="discuss-wrapper"
      />
    </Modal>,
    document.body
  );
};

export default LoadingOverlay;
