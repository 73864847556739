import React, { lazy, Suspense, useEffect } from "react";
import "./App.scss";

import ReactGA from "react-ga4";
import { Route, Routes } from "react-router-dom";
import { LoadingOverlay } from "./components/Loading";
const HomePage = lazy(() => import("./pages/Home"));

ReactGA.initialize("G-SZCGWS4FR0");

function App() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <Suspense fallback={<LoadingOverlay />}>
      <Routes>
        <Route path="/" element={<HomePage />} />
      </Routes>
    </Suspense>
  );
}

export default App;
